/**
 * 首页
 * luxinwen
 * 2023-04
 */
<style scoped lang="less">
  @import "./welcome.less";
</style>

<template>
  <div class="welcome" :style="mainStyle">
    <div class="welcome-main">
      <div class="welcome-main-content">
        <p>欢迎您，{{ userName }}</p>
        <p>{{ today }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        userName: '',
        clientHeight: 300,
        today: ''
      };
    },
    computed: {
      mainStyle() {
        return {
          minHeight: this.clientHeight + 'px'
        };
      }
    },
    created() {
      let userInfo = this.getUserInfo();
      this.userName = userInfo.username;
      let date = new Date();
      this.today = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    },
    mounted() {
      let docEl = document.documentElement;
      this.clientHeight = docEl.clientHeight || docEl.getBoundingClientRect().height;
      this.clientHeight -= (document.querySelector('.layout-main-head').clientHeight + 23);
    }
  };
</script>